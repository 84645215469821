import React, { Children, Component, Fragment, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { withUIContext } from 'context/ui';

import Clickable from 'components/clickable';
import { Container } from 'components/layout';

import s from './NavigationMobile.scss';

class NavigationMobile extends Component {

  static propTypes = {
    ui: PropTypes.object,
    children: PropTypes.node,
    button: PropTypes.element,
    logo: PropTypes.element,
  }

  componentDidMount() {
    document.addEventListener('touchmove', this.onTouchMove, { passive: false });
  }

  componentWillUnmount() {
    document.removeEventListener('touchmove', this.onTouchMove);
  }

  componentDidUpdate(prevProps) {
    const { mobileNavOpen } = this.props.ui;
    if (prevProps.ui.mobileNavOpen !== mobileNavOpen) {
      if (mobileNavOpen) {
        this.inner.scrollTop = 0;
      }
    }
  }

  onTouchMove = (e) => {
    if (e.target.className === s.navigationMobile__backdrop) {
      e.preventDefault();
    }
  }

  render() {
    const { ui, children, button, logo } = this.props;
    

    return (
      <Fragment>
        { ui.mobileNavOpen && (<Helmet htmlAttributes={{ class: 'is-scroll-disabled' }} />)}

        <div className={s('navigationMobile', {[s.open]: ui.mobileNavOpen })}>
          <button className={s.navigationMobile__backdrop} onClick={ui.toggleMobileNav} />
          <div className={s.navigationMobile__inner} ref={(el) => { this.inner = el; }}>
            <Container>
              {logo && <Clickable to="/" className={s.navigationMobile__logo}>{logo}</Clickable>}
              {children && (
                <ul className={s.navigationMobile__list}>
                  {Children.map(children, (child, i) => (
                    <li key={`${s.navigationMobile__item}-${i}`} className={s.navigationMobile__item}>
                      {cloneElement(child, {
                        className: s.navigationMobile__link,
                      })}
                    </li>
                  ))}
                  {button && (
                    <li className={s.navigationMobile__button}>
                      {cloneElement(button, { style: { display: 'flex'} })}
                    </li>
                  )}
                </ul>
              )}
            </Container>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withUIContext(NavigationMobile);
