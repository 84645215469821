
// title and <ul> of links, under NavigationSection
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';

import s from './NavigationList.scss';

const NavigationList = ({ heading, links }) => (
  <div className={s.navigationList}>
    <h2 className={s.navigationList__heading}>{heading}</h2>
    <ul className={s.navigationList__ul}>
      {links && links.map((link, i) => (
        <li key={`nav-list-item-${i}`} className={s.navigationList__item}>
          <Clickable className={s.navigationList__link} to={link.url} navLink>
            {link.text}
          </Clickable>
        </li>
      ))}
    </ul>
  </div>
);

NavigationList.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  })),
  heading: PropTypes.string,
};

export default NavigationList;
