// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-serial-js": () => import("../src/templates/product-serial.js" /* webpackChunkName: "component---src-templates-product-serial-js" */),
  "component---src-templates-text-page-js": () => import("../src/templates/text-page.js" /* webpackChunkName: "component---src-templates-text-page-js" */),
  "component---src-templates-product-js": () => import("../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-solution-js": () => import("../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-integrations-js": () => import("../src/templates/integrations.js" /* webpackChunkName: "component---src-templates-integrations-js" */),
  "component---src-templates-blog-js": () => import("../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-article-js": () => import("../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-resources-js": () => import("../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-developer-js": () => import("../src/pages/developer.js" /* webpackChunkName: "component---src-pages-developer-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installers-js": () => import("../src/pages/installers.js" /* webpackChunkName: "component---src-pages-installers-js" */),
  "component---src-pages-job-js": () => import("../src/pages/job.js" /* webpackChunkName: "component---src-pages-job-js" */),
  "component---src-pages-jobs-js": () => import("../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-love-js": () => import("../src/pages/love.js" /* webpackChunkName: "component---src-pages-love-js" */),
  "component---src-pages-news-js": () => import("../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pricing-calculate-js": () => import("../src/pages/pricing/calculate.js" /* webpackChunkName: "component---src-pages-pricing-calculate-js" */),
  "component---src-pages-pricing-index-js": () => import("../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-security-js": () => import("../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-vision-js": () => import("../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */)
}

