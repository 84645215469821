// wrapper for sub-nav content - imported into <NavigationSection />
import React, { Children } from 'react'
import PropTypes from 'prop-types'

import s from './NavigationContent.scss'

const NavigationContent = ({ children }) => {
  return (
    <div className={s.navigationContent}>
      <div className={s.navigationContent__container}>
        <ul className={s.navigationContent__inner}>
          {Children.map(children, (child, i) => {
            if (child) {
              // eslint-disable-next-line react/no-array-index-key
              return (
                <li
                  key={`${s.navigationContent__item}-${i}`}
                  className={s('navigationContent__item', {
                    moreThan3: React.Children.toArray(children).length > 3,
                  })}
                >
                  {child}
                </li>
              )
            }
          })}
        </ul>
      </div>
    </div>
  )
}

NavigationContent.propTypes = {
  children: PropTypes.node,
}

export default NavigationContent
