import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withUIContext } from 'context/ui';

import { HeaderMobile, HeaderDesktop } from '.';

class Header extends Component {

  state = {
    isFixed: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    this.onScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    requestAnimationFrame(this.scrollUpdate);
  }

  scrollUpdate = () => {
    const topOffset = window.pageYOffset;

    this.setState({
      isFixed: topOffset > 45,
    });
  }

  static propTypes = {
    ui: PropTypes.object,
  }

  render() {
    const { isMobile } = this.props.ui;
    const { isFixed } = this.state;

    if (isMobile) {
      return <HeaderMobile {...this.props} isFixed={isFixed} />;
    }

    return (
      <HeaderDesktop {...this.props} isFixed={isFixed} />
    );
  }
}

export default withUIContext(Header);
