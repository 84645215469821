import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withUIContext } from 'context/ui';

import { Container } from 'components/layout';
import Clickable from 'components/clickable';
import MenuButton from 'components/menu-button';

import s from './HeaderMobile.scss';

class HeaderMobile extends Component {

  static propTypes = {
    children: PropTypes.element,
    logo: PropTypes.element,
    ui: PropTypes.object,
    isFixed: PropTypes.bool,
  }

  static defaultProps = {
    children: undefined,
  }

  onMenuClick = () => {
    this.props.ui.toggleMobileNav();
  }

  render() {
    const { children, ui, logo, isFixed } = this.props;
    const { mobileNavOpen } = ui;

    return (
      <div className={s('headerMobile', ui.navTheme, { isFixed, mobileNavOpen })}>
        <Container>
          <div className={s.headerMobile__inner}>
            <div className={s.headerMobile__logoWrap}>
              <Clickable to="/" className={s.headerMobile__logo}>{logo}</Clickable>
            </div>
            <div className={s.headerMobile__button}>
              <MenuButton />
            </div>
          </div>
        </Container>
        {children}
      </div>
    );
  }
}

export default withUIContext(HeaderMobile);

