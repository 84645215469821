import React, { Children, Component, cloneElement } from 'react';
import PropTypes from 'prop-types';

import { withUIContext } from 'context/ui';

import NavigationSection from './NavigationSection';

import s from './NavigationDesktop.scss';

class NavigationDesktop extends Component {

  static propTypes = {
    children: PropTypes.node,
    button: PropTypes.element,
    isFixed: PropTypes.bool,
    ui: PropTypes.object,
  }

  static defaultProps = {
    children: undefined,
  }

  onOpenItem = (i) => {
    this.props.ui.setDesktopActiveNav(i);
  }

  onCloseItem = () => {
    this.props.ui.setDesktopActiveNav(-1);
  }

  render() {
    const { children, button, isFixed, ui } = this.props;

    return (
      <div className={s('navigation', ui.navTheme, { [s.active]: ui.desktopActiveNav !== -1, isFixed })}>
        <ul className={s.navigation__list}>
          {Children.map(children, (child, i) => {
            const isNavigationSection = child.type === (<NavigationSection />).type;
            const isOpen = ui.desktopActiveNav === i;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i} className={s.navigation__item}>
                {isNavigationSection ? cloneElement(child, {
                  onEnter: () => { this.onOpenItem(i); },
                  onLeave: () => { this.onCloseItem(); },
                  className: s.navigation__link,
                  isOpen,
                }) : cloneElement(child, {
                  className: s.navigation__link,
                })}
              </li>
            );
          })}
          <li className={s.navigation__button}>{button}</li>
        </ul>
      </div>
    );
  }
}

export default withUIContext(NavigationDesktop);
