import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';
import Icon from 'components/icon';

import s from './Button.scss';

export default class Button extends PureComponent {

  static propTypes = {
    to: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf(['red', 'white', 'transparent', 'borderRed']),
    arrow: PropTypes.bool,
    icon: PropTypes.string,
    size: PropTypes.oneOf(['large', 'default']),
  }

  static defaultProps = {
    children: (<span>Read more</span>),
    color: 'red',
    arrow: false,
    disabled: false,
    size: 'default',
  }

  render() {
    const {
      to,
      children,
      className,
      disabled,
      color,
      arrow,
      icon,
      size,
      ...rest
    } = this.props;

    const content = arrow ? <Icon id="arrow-right" /> : children;

    rest.className = s('button', className, size,
      { disabled, arrow, [color]: !disabled && !arrow });

    return (
      <Clickable to={to} {...rest}>
        {icon && (
        <span className={s.button__iconWrap}>
          <Icon id={icon} className={s.button__icon} />
        </span>)}
        {content}
      </Clickable>
    );
  }
}

