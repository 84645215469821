import React from 'react'
import PropTypes from 'prop-types'
import s from './Layout.scss'

const Row = ({ children, reversed, centered }) => (
  <div className={s('row', { [s.reversed]: reversed, centered })}>
    {children}
  </div>
)

Row.propTypes = {
  children: PropTypes.node,
  reversed: PropTypes.bool,
  centered: PropTypes.bool,
}

Row.defaultProps = {
  children: undefined,
  reversed: false,
  centered: false,
}

export default Row
