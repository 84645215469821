import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';

import s from './Layout.scss';

const Segment = ({ children, container }) => {

  const content = container ? (<Container>{children}</Container>) : children;

  return (
    <div className={s.segment}>
      {content}
    </div>
  );
};

Segment.propTypes = {
  children: PropTypes.node,
  container: PropTypes.bool,
};

Segment.defaultProps = {
  children: undefined,
  container: false,
};

export default Segment;
