import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { UIProvider } from 'context/ui';

import Content from 'components/content';
import DevTools from 'components/dev-tools';

import Header from 'containers/header';
import Footer from 'components/footer';

import shareImage from 'assets/images/share-image.jpg';

import './AppLayout.scss';

export default class AppLayout extends Component {

  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
  }

  static defaultProps = {
    children: null,
  }

  componentDidMount() {
    document.querySelector('html').classList.remove('no-js');
  }

  render() {
    const { children, location } = this.props;

    const isPricingCalculator = location.pathname.includes('/pricing/calculate');

    return (
      <UIProvider>
        <DevTools />
        <Helmet
          meta={[
            { property: 'og:image', content: shareImage },
            { name: 'twitter:image', content: shareImage },
          ]}
        />
        <Content>
          { /* TODO: better */ }
          {!isPricingCalculator && (<Header />)}
          {children}
          {!isPricingCalculator && (<Footer />)}
        </Content>
      </UIProvider>
    );
  }
}
