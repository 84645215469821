import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import kebabCaseIcon from 'utils/kebab-case-icon'
import ProxyLogo from 'assets/svg/proxy-logo-text.svg'

import Button from 'components/button'
import Clickable from 'components/clickable'
import Header from 'components/header'
import Navigation, {
  NavigationSection,
  NavigationItem,
  NavigationList,
} from 'components/navigation'
import UtilityNav from 'components/utility-nav'

const hasLetters = text => text?.search(/[A-Za-z]+/) >= 0;

const HeaderContainer = ({ links, superLinks, buttonLink }) => {
  const linksForCN = [...links].splice(0, links.length -1);
  const linkButtons = hasLetters(links[1].heading) ? links : linksForCN;

  return (
    <Header
      utility={
        superLinks && (
          <UtilityNav>
            {superLinks.map(link => (
              <Clickable key={link.heading} to={link.url}>
                {link.heading}
              </Clickable>
            ))}
          </UtilityNav>
        )
      }
      logo={<ProxyLogo />}
    >
      <Navigation
        logo={<ProxyLogo />}
        button={
          buttonLink && hasLetters(buttonLink.heading) ? (
            <Button size="large" to={buttonLink.url}>
              {buttonLink.heading}
            </Button>
          ) : (
            undefined
          )
        }
      >
        {linkButtons &&
          linkButtons.map(section =>
            section.url ? (
              <Clickable key={section.heading} to={section.url}>
                {section.heading}
              </Clickable>
            ) : (
              <NavigationSection
                heading={section.heading}
                key={section.heading}
              >
                {section.links &&
                  section.links.map(link => (
                    <NavigationItem
                      key={link.heading}
                      icon={kebabCaseIcon(link.icon)}
                      heading={link.heading}
                      text={link.textLong}
                      link={link.url}
                    />
                  ))}
                {section.lists &&
                  section.lists.map(list => (
                    <NavigationList
                      key={list.heading}
                      heading={list.heading}
                      links={
                        list.links &&
                        list.links.map(link => ({
                          text: link.heading,
                          url: link.url,
                        }))
                      }
                    />
                  ))}
              </NavigationSection>
            )
          )}
      </Navigation>
    </Header>
  )
}

const query = graphql`
  fragment navLink on ContentfulNavigationLink {
    icon
    heading: title
    textLong: longBody
    textShort: shortBody
    url
  }

  fragment navGroup on ContentfulNavigationGroup {
    heading: title
    links: items {
      ...navLink
    }
    lists: list {
      heading: title
      links: items {
        ...navLink
      }
    }
  }

  query Header {
    nav: contentfulNavigation {
      links: headerLinks {
        ...navGroup
        ...navLink
      }
      superLinks: superHeaderLinks {
        ...navLink
      }
      buttonLink {
        ...navLink
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={query}
    render={data => (
      <HeaderContainer
        links={data.nav.links}
        superLinks={data.nav.superLinks}
        buttonLink={data.nav.buttonLink}
        {...props}
      />
    )}
  />
);

HeaderContainer.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string.isRequired,
          icon: PropTypes.string,
          url: PropTypes.string,
          textShort: PropTypes.string,
          textLong: PropTypes.string,
        }),
      ),
      lists: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
    }),
  ),
  superLinks: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  buttonLink: PropTypes.shape({
    heading: PropTypes.string,
    url: PropTypes.string,
  }),
};
