import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import s from './UtilityNav.scss';

export default class UtilityNav extends PureComponent {

  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
    children: undefined,
  }

  render() {

    const { children } = this.props;
    const childArray = Children.toArray(children);

    return (
      <div className={s.utilityNav}>
        <div className={s.utilityNav__inner}>
          <div className={s.utilityNav__container}>
            <ul className={s.utilityNav__list}>
              {childArray.map((child, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i} className={s.utilityNav__item}>
                  {cloneElement(child, { className: s.utilityNav__link })}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
