import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withUIContext } from 'context/ui';

import s from './MenuButton.scss';

class MenuButton extends Component {

  scrollbarWidth = 0;

  static propTypes = {
    ui: PropTypes.object,
  }

  componentDidMount() {
    this.scrollbarWidth = this.getScrollbarWidth();
  }

  getScrollbarWidth = () => window.innerWidth - document.documentElement.clientWidth;

  onClick = () => {
    this.props.ui.toggleMobileNav();
  }

  render() {
    const { theme, mobileNavOpen } = this.props.ui;

    const style = mobileNavOpen && this.scrollbarWidth > 0 ? { left: `-${this.scrollbarWidth}px` } : { left: 'auto' };

    return (
      <button style={style} className={s('menuButton', theme, { [s.close]: mobileNavOpen })} onClick={this.onClick}>
        <span className={s.menuButton__inner}>menu</span>
      </button>
    );
  }
}

export default withUIContext(MenuButton);

