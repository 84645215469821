import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withUIContext } from 'context/ui';

import { NavigationDesktop, NavigationMobile } from '.';

class Navigation extends PureComponent {

  static propTypes = {
    ui: PropTypes.object,
  }

  render() {
    const { ui } = this.props;

    if (!ui.isMobile) return <NavigationDesktop {...this.props} />;

    return <NavigationMobile {...this.props} />;
  }
}

export default withUIContext(Navigation);
