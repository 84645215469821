// Section is the wrapper around a sub-nav
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withUIContext } from 'context/ui';

import Icon from 'components/icon';

import { NavigationContent } from '.';

import s from './NavigationSection.scss';

class NavigationSection extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    isOpen: PropTypes.bool, // one of the menu items is active
    children: PropTypes.node,
    ui: PropTypes.object,
  }

  static defaultProps = {
    heading: '',
    onEnter: () => null,
    onLeave: () => null,
    isOpen: false,
  }

  onMouseEnter = () => {
    if (!this.props.ui.isMobile) {
      this.props.onEnter();
    }
  }

  onMouseLeave = () => {
    if (!this.props.ui.isMobile) {
      this.props.onLeave();
    }
  }

  onClick = () => {
    // we doing anything here?
  }

  render() {
    const { heading, isOpen, children, ui } = this.props;

    const title = ui.isMobile ?
      <h2 className={s.navigationSection__heading}>{heading}</h2> :
      <button className={s.navigationSection__heading} onClick={this.onClick}>{heading}</button>;

    return (
      <div
        className={s('navigationSection', { isOpen })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className={s.navigationSection__headingWrap}>
          {title}
          <div className={s.navigationSection__indicator}>
            {!ui.isMobile && <Icon id="chevron" className={s.navigationSection__chevron} />}
          </div>
        </div>

        <div className={s.navigationSection__content}>
          <NavigationContent>
            {children}
          </NavigationContent>
        </div>
      </div>
    );
  }
}

export default withUIContext(NavigationSection);
