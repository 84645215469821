import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import s from './Markdown.scss';

export default class Markdown extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    source: PropTypes.string,
    cols: PropTypes.oneOf([1, 2, 3, 4]),
  }

  static defaultProps = {
    cols: 1,
  }

  render() {
    const { source, className, cols } = this.props;

    return (
      <ReactMarkdown source={source} className={s('markdown', `columnCount${cols}`, className)} />
    );
  }
}
