// icon + title + text child of NavigationContent
import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Clickable from 'components/clickable';

import s from './NavigationItem.scss';

const NavigationItem = ({ heading, text, link, icon }) => (
  <div className={s.navigationItem}>
    <div className={s.navigationItem__iconWrap}>
      <Icon className={s.navigationItem__icon} id={icon} />
    </div>
    <h2 className={s.navigationItem__heading}>
      <Clickable className={s.navigationItem__link} to={link}>{heading}</Clickable>
    </h2>
    <p className={s.navigationItem__text}>{text}</p>
  </div>
);

NavigationItem.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
};

NavigationItem.defaultProps = {
  icon: 'none',
};

export default NavigationItem;
