/* wrapper that just closes all open nav instances on route change */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withUIContext } from 'context/ui';

class Content extends PureComponent {

  static propTypes = {
    ui: PropTypes.shape({
      closeNav: PropTypes.func,
    }),
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      /* eslint-disable-next-line react/prop-types */
      this.props.ui.closeNav();
    }
  }

  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
    children: undefined,
  }

  render() {
    return this.props.children;
  }
}

export default withUIContext(Content);
