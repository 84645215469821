import React from 'react'
import PropTypes from 'prop-types'

import s from './Icon.scss'

const iconList = [
  'access',
  'badge',
  'battery-protection',
  'beacon',
  'biometric',
  'bug-bounty',
  'buildings',
  'card',
  'cards',
  'city',
  'cloud-management',
  'customization',
  'document-security',
  'document',
  'ease-of-use',
  'easy-to-install',
  'elevator',
  'existing-infrastructure',
  'flag',
  'front-desk',
  'futureproof',
  'globe',
  'hand',
  'handsfree',
  'hospitality',
  'infinity',
  'landlord-tenant',
  'link',
  'lock',
  'no-batteries',
  'offices',
  'onboarding',
  'optimization',
  'over-the-air',
  'personalization',
  'pricing',
  'responsive-workplace',
  'security',
  'smart-reader-plus',
  'smartphone',
  'smartwatch',
  'sparks',
  'ultimate-workplace',
  'unlock',
  'upgrade-readers',
  'upgrade-system',
  'visitor-access',
  'visitor',
  'zone-share',
  // other icons:
  'arrow-down',
  'arrow-right',
  'arrow-rounded',
  'arrow-rounded-large',
  'cart',
  'check',
  'chevron',
  'chevron-rounded',
  'chevron-rounded-large',
  'close',
  'download',
  'envelope',
  'handle',
  'no',
  'no-small',
  'play',
  'pause',
  'question',
  'yes',
  // social medias:
  'social-facebook',
  'social-instagram',
  'social-twitter',
  'social-youtube',
  'social-linkedin',
  'social-email',
]

const components = {}

iconList.forEach(icon => {
  // eslint-disable-next-line import/no-dynamic-require
  const component = require(`assets/svg/icons/${icon}.svg`)

  components[icon] = component
})

const Icon = ({ id, className }) => {
  if (typeof id !== 'string' || iconList.indexOf(id) === -1) {
    return null
  }

  const RenderedIcon = components[id]

  return <RenderedIcon className={s('icon', className)} />
}

export default Icon

Icon.propTypes = {
  id: PropTypes.oneOf(Object.keys(components)).isRequired,
  className: PropTypes.string,
}
