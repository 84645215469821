import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import Clickable from 'components/clickable';

import s from './HeaderDesktop.scss';

export default class HeaderDesktop extends Component {

  state = {
    activeNav: -1,
  }

  static propTypes = {
    children: PropTypes.element,
    utility: PropTypes.element,
    logo: PropTypes.element,
    ui: PropTypes.object,
    isFixed: PropTypes.bool,
  }

  static defaultProps = {
    children: undefined,
    utility: null,
  }

  onUpdateNav = (i) => {
    this.setState({
      activeNav: i,
    });
  }

  onCloseNav = () => {
    this.setState({
      activeNav: -1,
    });
  }

  render() {

    const { utility, children, ui, logo, isFixed } = this.props;
    const { activeNav } = this.state;
    const isActive = ui.desktopActiveNav !== -1;

    return (
      <header className={s('header', ui.navTheme, { isFixed, isActive })}>
        {utility}
        <div className={s.header__inner}>
          <div className={s.header__container}>
            <Clickable to="/" className={s.header__logo}>{logo}</Clickable>

            <div className={s.header__navigation}>
              {cloneElement(children, {
                activeNav,
                onUpdate: this.onUpdateNav,
                onClose: this.onCloseNav,
                isMobile: false,
                theme: ui.navTheme,
                isFixed: isFixed || isActive,
              })}
            </div>
          </div>
        </div>
      </header>
    );
  }
}
