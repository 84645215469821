const kebabCaseTransform = (text) => {
  const hasIcon = text !== 'None';

  if (hasIcon) {
    const nameWithDashes = text.replace(/ /g, '-');

    return nameWithDashes.toLowerCase();
  }
  return null;
};

export default kebabCaseTransform;
