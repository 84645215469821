/* eslint-disable jsx-a11y/anchor-has-content */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import url from 'url';
import { Link } from 'gatsby';
import ArrowOutbound from 'assets/svg/icons/arrow-outbound.svg';

export default class Clickable extends Component {

  static propTypes = {
    to: PropTypes.string,
    disabled: PropTypes.bool,
    navLink: PropTypes.bool,
  }

  static defaultProps = {
    to: undefined,
    disabled: false,
    navLink: false,
  }

  state = {
    error: false,
  }

  componentDidCatch(error, info) {
    this.setState({ error: true });
    console.error(error, info);
  }

  render() {
    const { error } = this.state;

    if (error) {
      return null;
    }

    const { to, navLink, ...rest } = this.props;

    if (!to) {
      return <button type="button" {...rest} />;
    }

    if (to.startsWith('#') || to.startsWith('mailto:') || to.startsWith('tel:')) {
      return <a href={to} {...rest} />;
    }

    const { hostname, path } = url.parse(to);

    if (['proxy.co', 'proxy.com', 'www.proxy.co', 'www.proxy.com', null].includes(hostname)) {

      return (
        <Link {...rest} to={path} />
      );
    }

    return (
      <a
        rel="noopener noreferrer"
        {...rest}
        href={to}
        target="_blank"
      >
        {rest.children}
        {navLink && (
          <ArrowOutbound
            style={{
              marginLeft: '10px',
            }}
          />
        )}
      </a>
    );
  }
}
